<template>
	<v-flex v-if="model" :shrink="!vertical">
		<v-spacer v-if="model.isBlank" />
		<v-subheader v-else-if="model.isSubHeader || model.isMessage" class="font-weight-bold">{{ model.text }}</v-subheader>
		<NodeItem
			v-else
			class="ma-1 no-user-select"
			:shrink="!vertical"
			:is-back-to-parent-folder="model.isBackToParentFolder"
			:small="small"
			:medium="medium"
			:large="false"
			:search="search"
			:vertical="vertical"
			:value="model.node"
			v-bind="$attrs"
			do-not-show-subfolders-as-opened
		/>
	</v-flex>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'FolderContentNode',
	components: {
		NodeItem: () => ({
			component: import('@/components/Documents/Content/NodeItem')
		})
	},
	mixins: [DocumentsManagerModuleGuard],
	inheritAttrs: false,
	props: {
		displayMode: {
			type: String,
			required: false,
			default: 'mosaic'
		},
		search: {
			type: String,
			required: false,
			default: ''
		},
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		vertical: function () {
			return this.displayMode == 'mosaic'
		},
		small: function () {
			return this.displayMode == 'list'
		},
		medium: function () {
			return this.displayMode == 'mosaic'
		}
	}
}
</script>

<style scoped>
.no-user-select {
	user-select: none;
}
</style>
